* {
  font-family: "Sofia Pro";
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  margin-top: 3em !important;
  padding: 10px 40px !important;
  color: white !important;
  border-radius: 8px !important;
}

#root {
  min-width: 420px;
  background-color: #f9f9f9;
}

/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
  font-family: "Lora";
  src: url("fonts/Lora-Regular.woff2") format("woff2"),
    url("fonts/Lora-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lora";
  src: url("fonts/Lora-Italic.woff2") format("woff2"),
    url("fonts/Lora-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Lora";
  src: url("fonts/Lora-Bold.woff2") format("woff2"),
    url("fonts/Lora-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lora";
  src: url("fonts/Lora-BoldItalic.woff2") format("woff2"),
    url("fonts/Lora-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("fonts/SofiaProRegular-Italic.woff2") format("woff2"),
    url("fonts/SofiaProRegular-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("fonts/SofiaPro-Bold.woff2") format("woff2"),
    url("fonts/SofiaPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("fonts/SofiaPro-BoldItalic.woff2") format("woff2"),
    url("fonts/SofiaPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("fonts/SofiaProRegular.woff2") format("woff2"),
    url("fonts/SofiaProRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* ------------------------------------------------------------ *\
	App
\* ------------------------------------------------------------ */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.app-bar {
  padding: 0px 10px;
  background-color: white !important;
}

.wordmark {
  font-family: "Sofia Pro";
  color: #22272b;
  font-weight: bold;
  font-size: 1.5em;
}

.powered-by {
  letter-spacing: 1.2px;
  color: #9292a8;
  position: relative;
  bottom: 2px;
}

/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.btn-hero .MuiButton-label .btn-label {
  position: relative;
  top: 2px;
}

.flex-text-box-inner {
  width: fit-content;
}

.panel-form-label {
  color: #22272b;
  font-weight: bold;
  font-size: 240%;
  line-height: 220%;
  padding-right: 12px;
}

/*
 * Use 'margin-bottom' and 'top' to control the location of the
 * input text field underlines
 */

.icon-check {
  font-size: 500% !important;
  opacity: 0.5;
}
